// @ts-ignore
let apiUrl = import.meta.env.VITE_API_BASE_URL;

const defaultFetchTimeout = 60000;

let fetchMethod = fetch;

export function setFetchMethod(method: typeof fetch) {
  fetchMethod = method;
}

async function fetchWithTimeout(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  timeout?: number
) {
  const controller = new AbortController();
  const id = setTimeout(
    () => controller.abort(`Request timed out after ${timeout}ms`),
    timeout ?? defaultFetchTimeout
  );
  const response = await fetchMethod(input, {
    ...init,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
}

export async function get<T>(
  uri: string,
  token?: string,
  timeout?: number
): Promise<T> {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await fetchWithTimeout(
    `${apiUrl}${uri}`,
    {
      headers,
    },
    timeout
  );
  const json = await response.json();
  if (json.error) {
    throw new Error(`${json.error} - ${json.message}`);
  }
  return json;
}

// Post
export async function post<T>(
  uri: string,
  body: any,
  token?: string,
  timeout?: number
): Promise<T> {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await fetchWithTimeout(
    `${apiUrl}${uri}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    },
    timeout
  );
  const json = await response.json();
  if (json.error) {
    throw new Error(`${json.error} - ${json.message}`);
  }
  return json;
}

// Post file
export async function postFile<T>(
  uri: string,
  file: File,
  token?: string,
  timeout?: number
): Promise<T> {
  const headers: HeadersInit = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  let formData = new FormData();
  formData.append("file", file);
  const response = await fetchWithTimeout(
    `${apiUrl}${uri}`,
    {
      method: "POST",
      headers,
      body: formData,
    },
    timeout
  );
  const json = await response.json();
  if (json.error) {
    throw new Error(`${json.error} - ${json.message}`);
  }
  return json;
}

// Put
export async function put<T>(
  uri: string,
  body: any,
  token?: string,
  timeout?: number
): Promise<T> {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await fetchWithTimeout(
    `${apiUrl}${uri}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    },
    timeout
  );
  const json = await response.json();
  if (json.error) {
    throw new Error(`${json.error} - ${json.message}`);
  }
  return json;
}

// Delete
export async function del<T>(
  uri: string,
  token?: string,
  timeout?: number
): Promise<T> {
  const headers: HeadersInit = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await fetchWithTimeout(
    `${apiUrl}${uri}`,
    {
      method: "DELETE",
      headers,
    },
    timeout
  );
  const json = await response.json();
  if (json.error) {
    throw new Error(`${json.error} - ${json.message}`);
  }
  return json;
}
